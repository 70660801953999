import { debugEnable } from '../utils/utils';
import { notification } from 'antd';

export function handleError(e, msg, callback) {
  if (debugEnable) console.log('[handleError]:', e, msg);
  if (!callback) {
    callback = function (val) {
      notification.destroy();
      notification.error({
        message: val,
        placement: 'top',
        duration: 0,
      });
    };
  }
  if (e) {
    notification.destroy();
    if (e.status === 400 && e.data?.title) {
      notification.error({
        message: e.data.title,
        placement: 'top',
        duration: 0,
      });
      return;
    }
    if (e.status === 401) {
      notification.error({
        message: '您的登录信息已过期，请刷新页面！',
        placement: 'top',
        duration: 0,
      });
      return;
    }
    if (e.status === 403) {
      notification.error({
        message: '您没有相应的操作权限，请联系管理员！',
        placement: 'top',
        duration: 0,
      });
      return;
    }
    if (e.status === 0) {
      notification.error({
        message: '网络信息异常，请稍后重试！',
        placement: 'top',
        duration: 0,
      });
      return;
    }
  }
  callback(msg || '系统发生异常，请联系IS处理！');
}
