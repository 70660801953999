export const debugEnable = process.env.NODE_ENV === 'development';

export function showTableTotal(total, range) {
  return `${range[0]}-${range[1]} of ${total} items`;
}

export function stringSorter(a, b, index) {
  let stringA = a[index];
  let stringB = b[index];
  if (stringA && stringB) {
    stringA = stringA.toUpperCase();
    stringB = stringB.toUpperCase();
  }
  if (stringA < stringB) return -1;
  if (stringA > stringB) return 1;
  return 0;
}

export function numberSorter(a, b, index) {
  return a[index] - b[index];
}

/**
 * 判断是否是中文
 * @param {*} str
 * @returns
 */
const isChinese = str => {
  let lst = /[u00-uFF]/;
  return !lst.test(str);
};

/**
 * 中英混合技术字符长度
 * @param {*} str
 */
export const strLen = str => {
  let strLength = 0;
  for (let i = 0; i < str.length; ++i) {
    if (isChinese(str.charAt(i)) === true) {
      strLength = strLength + 2; //中文算2个字符
    } else {
      strLength = strLength + 1;
    }
  }

  return strLength;
};

export function deepTrim(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      const type = typeof obj[key];
      if (type === 'string') {
        obj[key] = obj[key].trim();
      } else if (type === 'object') {
        deepTrim(obj[key]);
      }
    }
  });
}

export function emailToDisplayName(input) {
  if (input) {
    const index = input.indexOf('@');
    if (index > 0) {
      let s = input
        .slice(0, index)
        .toLowerCase()
        .replace('.', ' ')
        .replace(/\b(.+?)\b/g, s => {
          if (s && s.length > 0) {
            return s.length > 1
              ? s[0].toUpperCase() + s.slice(1)
              : s[0].toUpperCase();
          }
          return s;
        });
      return s;
    }
    return input;
  }
  return '';
}
