const messages = {
  'p.login.error':
    "Something went wrong and we can't sign you in right now. Please try again.",
  'p.login.welcome': 'Welcome to Document Management',
  'p.login.employee': 'ABB Employee',
  'p.login.employeetip': 'Sign in with ABB email',
  'p.login.customer': 'ABB Customer',
  'p.login.customertip': 'Query my orders',
  'p.errorpage.403': 'FORBIDDEN',
  'p.errorpage.404': 'PAGE NOT FOUND',
  'p.errorpage.home': 'BACK TO HOME PAGE',
};
export default messages;
