const messages = {
  'e.get': '获取{name}失败',
  'e.getlist': '获取{name}列表失败',
  'e.valfield': '验证{label}失败',
  'e.exists': '{value}已存在',
  'e.notexists': '{value}不存在',
  'e.document.isediting': '当前文档正在被编辑',
  'e.document.editingby': '文档正在被{user}起草中',
  'e.notauthor': '您没有相应的操作权限，请检查登录邮箱。',
  'e.docnotexist': '找不到文档',
};
export default messages;
