const messages = {
  'menu.home': '首页',
  'menu.documentmanagement': '文档管理',
  'menu.relatedtome': '与我相关',
  'menu.searchdocumentnumber': '文档编号查询',
  'menu.allversions': '文档发起记录',

  'menu.masterdatasetting': '主数据管理',
  'menu.tagmanagement': '文档标签管理',
  'menu.treenode': '文档主数据',
  'menu.systemsetting': '系统管理',
  'menu.processsetting': '流程配置',
  'menu.emailtemplatesetting': '添加邮件模板',
  'menu.emailtemplate': '邮件模板',
  'menu.helpmanagement': '帮助管理',
  'menu.help': '帮助',

  'menu.adjustapproversetting': '修改审批人',
  'menu.documentstructuretree': '文档结构树',
  'menu.documentcreate': '创建文档',
  'menu.documentedit': '编辑草稿',
  'menu.documentupdate': '文档升版',
  'menu.initiateddocument': '我发起的文档',
  'menu.approveddocument': '我审批的文档',
  'menu.subscriptiondepartment': '订阅部门',
  'menu.invalidateddocument': '作废文档管理',
};
export default messages;
