const messages = {
  'b.login': '登录',
  'b.abbemaillogin': 'ABB邮箱登录',
  'b.search': '搜索',
  'b.save': '保存',
  'b.submit': '提交',
  'b.cancel': '取消',
  'b.upload': '上传',
  'b.additem': '添加',
  'b.add': '新建',
  'b.edit': '编辑',
  'b.delete': '删除',
  'b.view': '查看',
  'b.yes': '是',
  'b.no': '否',
  'b.export': '导出',
  'b.import': '导入',
  'b.detail': '详情',
  'b.workflowchart': '流程图',
  'b.approve': '同意',
  'b.reject': '拒绝',
  'b.return': '退回',
  'b.approval': '审批',
  'b.receipt': '确认收货',
  'b.confirm': '确认',
  'b.check': '校验',
  'b.sparrow': 'Sparrow',
  'b.download': '下载',
  'b.more': '更多',
  'b.reset': '重置',
  'b.viewattachments': '查看附件',
  'b.adddata': '添加数据',
  'b.back': '返回',
  'b.collapse': '收起',
  'b.expand': '展开',
  'b.preview': '预览',
  'b.historyversion': '历史版本',
  'b.subscription': '订阅',
  'b.unsubscription': '取消订阅',
  'b.openstructuretree': '查看结构树',
};
export default messages;
