import React from 'react';
import { SoundOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

const Announcement = props => {
  const intl = useIntl();
  const onClick = () => {
    window.location.href = '#/announcement';
  };
  return (
    <div {...props} onClick={e => onClick()}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <SoundOutlined />
        {intl.formatMessage({ id: 'layout.announcement' })}
      </span>
    </div>
  );
};
export default Announcement;
