import error from './en-US/error';
import menu from './en-US/menu';
import pages from './en-US/pages';

const enUS = {
  'common.loading': 'Loading...',
  'layout.appname': 'DocumentManagement',
  'layout.lang': 'Languages',
  'layout.signout': 'Sign Out',
  ...error,
  ...menu,
  ...pages,
};
export default enUS;
