const messages = {
  'p.login.error': '登录失败，请重新登陆',
  'p.login.welcome': '文档管理系统',
  'p.login.cboluser': 'cBOL用户',
  'p.login.spuser': '服务商',
  'p.linkregister': '没有账号，注册一个？',
  'p.linkforgotpwd': '忘记密码？',
  'p.login.or': '或',
  'p.errorpage.403': '访问受限',
  'p.errorpage.404': '页面不存在',
  'p.errorpage.home': '返回首页',
  'p.table.action': '操作',
  'p.table.createdby': '创建人',
  'p.table.createdtime': '创建时间',
  'p.table.lastupdatedby': '更新人',
  'p.table.lastupdatedtime': '更新时间',
  'p.table.remark': '备注说明',
  'p.documenttag.tagcode': '标签编码',
  'p.documenttag.tagname': '标签名称',
  'p.documenttag.tagtype': '标签类型',
  'p.documenttag.relatedtagcode': '关联标签',
  'p.documenttag.sequence': '顺序',
  'p.documenttag.nodeid': '树结构',
  'p.documenttag.rules': '规则',
  'p.documenttag.tagtype.0': '自定义',
  'p.documenttag.tagtype.1': '文本',
  'p.documenttag.tagtype.2': '数字',
  'p.documenttag.tagtype.3': '日期',
  'p.documenttag.tagtype.4': '下拉列表（单选）',
  'p.documenttag.tagtype.5': '下拉列表（多选）',
  'p.documenttag.tagtype.6': '树（单选）',
  'p.documenttag.tagtype.7': '树（多选）',
  'p.documenttag.getlisterror': '获取标签列表失败',

  'p.treenode.parentname': '节点位置',
  'p.treenode.nodecode': '节点编码',
  'p.treenode.name.en-US': '英文名称',
  'p.treenode.name.zh-CN': '中文名称',
  'p.treenode.dataclassification': '数据级别',
  'p.treenode.sequenceno': '排序',
  'p.treenode.remark': '备注',

  'p.docdetail.pagetitle': '查看详情',
  'p.docdetail.docinfo': '文档信息',
  'p.docdetail.sn': '文档编号',
  'p.docdetail.title': '文档标题',
  'p.docdetail.version': '版本',
  'p.docdetail.dataclassification': '数据级别',
  'p.docdetail.visitor': '访问人员',
  'p.docdetail.file': '文档',
  'p.docdetail.attachments': '关联附件',
  'p.docdetail.editfile': '编辑文档',
  'p.docdetail.edittag': '编辑标签',
  'p.docdetail.invalidate': '作废文档',
  'p.docdetail.status': '状态',
  'p.docdetail.document_structure_tree': '文档结构树',
  'p.docdetail.newversion.released':
    '文档有已发布的新版本，请前往<a>新版本</a>进行编辑',
  'p.docdetail.newversion.unreleased':
    '文档有未发布的新版本，请前往<a>新版本</a>进行编辑',

  'p.approvallog.action': '由{user}{action}',
  'p.approvallog.pendingby': '等待{user}审批',
  'p.approvallog.submit': '提交',
  'p.approvallog.approve': '通过',
  'p.approvallog.reject': '拒绝',
  'p.approvallog.removeapprover': '移除',
  'p.approvallog.addapprover': '添加',

  'p.doc.moveto': '移动至',
  'p.doc.addto': '添加至',
  'p.doc.viewhistory': '查看历史版本',
  'p.doc.approvers': '审批人信息',
  'p.doc.approver.review': '审核人员',
  'p.doc.approver.countersign': '会签人员',
  'p.doc.approver.release': '批准人',
  'p.doc.approvalhistory': '审批记录',
  'p.doc.approvalcomment': '审批意见',
  'p.doc.downloadaspdf': '下载为Pdf',

  'p.updateapprover.listpage.title': '调整审批人',
  'p.updateapprover.detailpage.title': '修改审批人',

  'p.document.create.basicinformation': '文档基本信息',
  'p.common.document': '文档',
  'p.common.relateddocument': '关联附件',
  'p.common.action': '操作',
  'p.common.updated': '已更新',
  'p.doctable.status': '状态',
  'p.doctable.status.draft': '起草中',
  'p.doctable.status.pendingreview': '待审核',
  'p.doctable.status.pendingcountersign': '待会签',
  'p.doctable.status.pendingrelease': '待发布',
  'p.doctable.status.approved': '已发布',
  'p.doctable.status.rejected': '已拒绝',
  'p.doctable.status.invalidated': '已作废',
  'p.doctable.pendingapproval': '待审批',
  'p.doctable.approved': '已审批',
  'p.doctable.department': '部门',
  'p.doctable.subscriptionstatus': '订阅状况',
  'p.doctable.applicantname': '提交人',
  'p.doctable.applicanttime': '提交时间',
  'p.doclist.allversion.pagetitle': '文档发起列表',

  'p.approval.pendingdoc': '待审批文档',
  'p.approval.pendingclassification': '待审批数据级别',
  'p.approval.approveddoc': '已审批文档',
  'p.approval.approvedclassification': '已审批数据级别',
  'p.approval.docpagetitle': '审批文档',
  'p.approval.classificationtitle': '审批数据级别变更',
  'p.approval.pending': '待审批',
  'p.approval.approved': '已审批',
  'p.approval.rejected': '已拒绝',

  'p.outbox.document': '文档',
  'p.outbox.classification': '数据级别',

  'p.subscription.subscription': '已订阅',
  'p.subscription.unsubscription': '未订阅',

  'p.emailtemplate.classify': '模板分类',
  'p.emailtemplate.name': '模板名称',
  'p.emailtemplate.code': '模板编码',
  'p.emailtemplate.subjecttemplate': '邮件标题',
  'p.emailtemplate.bodytemplate': '邮件内容',
  'p.emailtemplate.createtitle': '新建邮件模板',
  'p.emailtemplate.edittitle': '编辑邮件模板',

  'p.help.upload': '上传文件',
  'p.help.uploaderror': '请删除上传失败的附件',

  'p.docinvalidation.date': '作废执行日期',
  'p.docinvalidation.depts': '通知部门',
  'p.docinvalidation.remark': '作废理由',
  'p.docinvalidation.createdtime': '提交时间',
  'p.docinvalidation.createdby': '作废人员',
  'p.invalidated.title.tech': '技术文档',
  'p.invalidated.title.certificate': '证书类型',
  'p.invalidated.detailtitle': '作废文档详情',
  'p.invalidated.invalidatedinfo': '作废信息',

  'p.home.tab.producttype': '产品型号',
  'p.home.tab.certificatetype': '认证证书',
};
export default messages;
