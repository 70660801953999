import React, { useState, useEffect } from 'react';
import { Menu, Divider, Layout, notification, Dropdown, Row } from 'antd';
import {
  UserOutlined,
  ExportOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectTopMenus,
  selectMenus,
  selectUserType,
  selectUserEmail,
  selectName,
  signOut,
} from '../../features/auth/authSlice';
import { Link } from 'react-router-dom';
import './LayoutDefault.less';
import * as LoginService from '@/api/login';
import eventBus from '@/utils/eventBus';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleError } from '@/utils/handleError';
//import LanguageSetting from '../language/LanguageSetting';
import Announcement from '../announcement/Announcement';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function LayoutDefault({ noWrapper, noFooter, ...props }) {
  let year = new Date().getFullYear();
  const [selectTopMenuKey, setSelectedTopMenuKey] = useState('');
  const { Header, Content, Footer } = Layout;

  useEffect(() => {
    var topMenuCode = localStorage.getItem('topMenuCode');
    if (topMenuCode) {
      setSelectedTopMenuKey(topMenuCode);
    }

    if (!topMenuCode) {
      eventBus.addListener('selectedTopMenu', function (val) {
        setSelectedTopMenuKey(val);
        localStorage.setItem('topMenuCode', val);
      });
    }
  }, []);

  function handleTopMenuClick(e) {
    setSelectedTopMenuKey(e.key);
    localStorage.setItem('topMenuCode', e.key);
  }

  return (
    <Layout style={{ minHeight: '100vh', minWidth: '1000px' }} {...props}>
      <div className="app-header-wrapper">
        <Row
          className={'abbBar'}
          // style={{
          //   height: '32px',
          //   alignContent: 'center',
          // }}
        >
          <div className="logo"></div>
          <div className="header-name">文档管理系统</div>
        </Row>
        <Header>
          <div className="header-bar">
            <NavTopMenubar
              selectedKeys={[selectTopMenuKey]}
              onClick={handleTopMenuClick}
            />
            <Toolbar />
          </div>
        </Header>
      </div>
      <Content style={{ display: 'flex' }}>
        {noWrapper ? (
          props.children
        ) : (
          <div className="app-main-wrapper">
            <div className="app-main-content" style={{ width: '90vw' }}>
              {props.children}
            </div>
          </div>
        )}
      </Content>
      {noFooter ? null : (
        <Footer style={{ textAlign: 'center' }}>&copy; {year} ABB</Footer>
      )}
    </Layout>
  );
}

export default LayoutDefault;

/**
 * 头部菜单
 * @param {*} param0
 * @returns
 */
export function NavTopMenubar({ selectedKeys, onClick, ...props }) {
  const menus = useSelector(selectTopMenus);
  const childrenmenus = useSelector(selectMenus);

  const intl = useIntl();

  function getNavItems(items) {
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let navitem = {
        key: item.menuCode,
        label: (
          <Link to={item.url}>
            <span style={{ color: 'black' }}>
              {/* {item.menuCode === 'home' && (
                <HomeOutlined
                  style={{ fontSize: '16px', marginRight: '14px' }}
                />
              )} */}
              {intl.formatMessage({ id: 'menu.' + item.menuCode })}
            </span>
          </Link>
        ),
      };
      let childrens = childrenmenus.filter(x => x.parentID === item.id);
      let childrennavItems = [];
      for (let i = 0; i < childrens.length; i++) {
        let childrennavItem = {
          ...childrens[i],
          key: childrens[i].menuCode,
          label: intl.formatMessage({ id: 'menu.' + childrens[i].menuCode }),
          children: null,
        };
        childrennavItem.label = (
          <Link to={childrennavItem.url}>
            {intl.formatMessage({ id: 'menu.' + childrennavItem.menuCode })}
          </Link>
        );
        childrennavItems.push(childrennavItem);
      }
      if (childrennavItems.length > 0) {
        navitem.children = childrennavItems;
      }

      navItems.push(navitem);
    }
    return navItems;
  }

  return (
    <Menu
      className="topmenus"
      onClick={onClick}
      mode="horizontal"
      items={getNavItems(menus)}
      selectedKeys={selectedKeys}
      {...props}
    />
  );
}

/**
 * 左侧菜单
 * @param {*} param0
 * @returns
 */
// function NavLeftMenubar(props) {
//   const topmenus = useSelector(selectTopMenus);
//   const menus = useSelector(selectMenus);
//   const [openMenuKey, setOpenedMenuKey] = useState([]);
//   const intl = useIntl();
//   let selectedTopMenuID =
//     topmenus.length > 0
//       ? props.selectTopMenuID || topmenus[0].Id
//       : props.selectTopMenuID;
//   let defaultOpenMenuKey = props.openLeftMenuKey.split(',');

//   function handleLeftMenuClick(e) {
//     props.handleLeftMenuClick(e);
//   }

//   function handleSetBadgeCount() {
//     props.handleSetBadgeCount();
//   }

//   function getNavItems(items) {
//     let navItems = [];
//     for (let i = 0; i < items.length; i++) {
//       const item = items[i];
//       if (item.parentID !== selectedTopMenuID) {
//         continue;
//       }
//       let navitem = {
//         key: item.menuCode,
//         label: intl.formatMessage({ id: 'menu.' + item.menuCode }),
//       };
//       if (item.children && item.children.length > 0) {
//         navitem.children = [];
//         for (let j = 0; j < item.children.length; j++) {
//           const x = item.children[j];
//           let navcitem = {
//             key: x.menuCode,
//             label: (
//               <Link to={x.url === undefined ? '' : x.url}>
//                 {intl.formatMessage({ id: 'menu.' + x.menuCode })}
//                 <span id={x.menuCode} className="badgeCount">
//                   0
//                 </span>
//               </Link>
//             ),
//           };
//           if (x.children && x.children.length > 0) {
//             navcitem.children = [];
//             for (let k = 0; k < x.children.length; k++) {
//               const y = x.children[k];
//               let navccitem = {
//                 key: y.menuCode,
//                 label: (
//                   <Link to={y.url === undefined ? '' : y.url}>
//                     {intl.formatMessage({ id: 'menu.' + y.menuCode })}
//                     <span id={y.menuCode} className="badgeCount">
//                       0
//                     </span>
//                   </Link>
//                 ),
//               };
//               navcitem.children.push(navccitem);
//             }
//           }
//           navitem.children.push(navcitem);
//         }
//       } else {
//         navitem.label = (
//           <Link to={item.url}>
//             {intl.formatMessage({ id: 'menu.' + item.menuCode })}
//             <span id={item.menuCode} className="badgeCount">
//               0
//             </span>
//           </Link>
//         );
//       }
//       navItems.push(navitem);
//     }
//     return navItems;
//   }

//   return (
//     <Menu
//       className="leftmenus"
//       mode="inline"
//       openKeys={openMenuKey.length > 0 ? openMenuKey : defaultOpenMenuKey}
//       selectedKeys={[props.selectedKey]}
//       onClick={handleLeftMenuClick}
//       onOpenChange={openKeys => {
//         setOpenedMenuKey(openKeys);
//         handleSetBadgeCount();
//       }}
//       items={getNavItems(menus)}
//     />
//   );
// }

/**
 * 右侧操作按钮
 * @param {*} props
 * @returns
 */
export function Toolbar(props) {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const userEmail = useSelector(selectUserEmail);
  const name = useSelector(selectName);
  const userItems = [
    {
      label: '修改用户信息',
      key: 'edituser',
    },
  ];
  const helpItems = [
    { label: 'iTicket', key: 'iticket' },
    { label: intl.formatMessage({ id: 'layout.helppage' }), key: 'help' },
    {
      label: intl.formatMessage({ id: 'layout.accessrequest' }),
      key: 'access',
    },
  ];
  const handleUserMenuClick = key => {
    if (key === 'edituser') {
      var url = window.ENV_CFG.edituser_link;
      url = url.replace('{0}', userEmail);
      window.open(url, '_blank');
    }
  };
  const handleHelpClick = ({ key }) => {
    if (key === 'iticket') {
      window.open(window.ENV_CFG.iticket);
    } else if (key === 'access') {
      window.open(window.ENV_CFG.accessrequest);
    } else {
      history.push('/help');
    }
  };
  const handleLogout = () => {
    dispatch(signOut());
    if (userType === 0) {
      window.location.href = window.ENV_CFG.signout;
    } else {
      LoginService.logout()
        .then(res => {
          if (res.tag === 1) {
            window.location.reload();
          } else {
            notification.error({
              message: res.message,
              placement: 'top',
              duration: 0,
            });
          }
        })
        .catch(e => {
          handleError(e);
        });
    }
  };

  return (
    <div className="app-header-bar-controls">
      <div className="control-item">
        <Dropdown
          menu={{ items: helpItems, onClick: handleHelpClick }}
          className="lang"
        >
          <span>
            <QuestionCircleOutlined />
            <FormattedMessage id="layout.help" />
          </span>
        </Dropdown>
      </div>
      <div className="control-item">
        <Announcement className="lang" />
      </div>
      {/* <div className="control-item">
        <LanguageSetting className="lang" />
      </div> */}
      {name && name.length > 0 && (
        <React.Fragment>
          {/* <div className="control-item">
            <UserOutlined /> {name}
          </div> */}
          {userType === 2 && (
            <div className="control-item">
              <Dropdown
                overlay={
                  <Menu
                    items={userItems}
                    onClick={({ key, domEvent }) => {
                      domEvent.stopPropagation();
                      handleUserMenuClick(key);
                    }}
                  ></Menu>
                }
              >
                <div>
                  <UserOutlined /> {name}
                </div>
              </Dropdown>
            </div>
          )}
          {userType !== 2 && (
            <div className="control-item">
              <UserOutlined /> {name}
            </div>
          )}
          <Divider type="vertical" />
          <div className="control-item" onClick={handleLogout}>
            <ExportOutlined />
            <FormattedMessage id="layout.signout" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
