import { lazy } from 'react';
//import SideMenu from '../components/SideMenu';
import LayoutDefault from '../components/layout/LayoutDefault';

// auth: 验证权限
// menu: 菜单，如果为空与auth一致
// anonymous: 默认false
// layout: 默认LayoutDefault
// exact: 是否精确匹配
// routes: 子路由
const routes = [
  {
    auth: 'home',
    path: '/home',
    component: lazy(() => import('../features/home/HomePage')),
    layout: false,
  },
  {
    auth: 'treenode',
    path: '/treenode',
    component: lazy(() => import('../features/treeNode/Index')),
    layout: LayoutDefault,
  },
  {
    auth: 'documentcreatepage',
    path: '/document/create',
    component: lazy(() => import('../features/document/create/Index')),
    layout: LayoutDefault,
  },
  {
    auth: 'documentdraftpages',
    path: '/document/draft/:id',
    component: lazy(() => import('../features/document/draft/Index')),
    layout: LayoutDefault,
  },
  {
    auth: 'documentupdatepages',
    path: '/document/update/:id',
    component: lazy(() => import('../features/document/update/Index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tagmanagement',
    path: '/documenttag',
    component: lazy(() => import('../features/documentTag/TagList')),
    layout: LayoutDefault,
  },
  {
    auth: 'documentdetailpage',
    path: '/document/detail/:id',
    component: lazy(() => import('../features/document/detail/DocumentDetail')),
    layout: LayoutDefault,
  },
  {
    auth: 'documentapproval',
    path: '/document/approval/:id',
    component: lazy(() =>
      import('../features/document/detail/DocumentApproval')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'searchdocumentnumber',
    path: '/document/report/searchdocumentsn',
    component: lazy(() =>
      import('../features/document/report/serrchBySN/Index')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'relatedtome',
    path: '/relatedtome/:menucode/:submenucode?',
    component: lazy(() => import('../features/RelatedToMe/Home/Index')),
    layout: LayoutDefault,
  },
  {
    auth: 'allversions',
    path: '/document/report/allversions',
    component: lazy(() =>
      import('../features/document/report/allversions/DocumentList')
    ),
    layout: LayoutDefault,
  },
  {
    // 修改审批人
    auth: 'adjustapproversetting',
    path: '/document/pending',
    component: lazy(() =>
      import('../features/document/updateapprover/DocumentList')
    ),
    layout: LayoutDefault,
  },
  {
    // 修改审批人auth
    auth: 'adjustapproversetting',
    path: '/document/updateapprover/:id',
    component: lazy(() =>
      import('../features/document/updateapprover/UpdateApprover')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'approveddocument',
    path: '/dataclassification/approval/:id',
    component: lazy(() =>
      import('../features/document/detail/DataClassificationApproval')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'invalidateddocument',
    path: '/invalidated',
    redirect: '/invalidated/tech',
  },
  {
    auth: 'invalidateddocument',
    path: '/invalidated/tech',
    component: lazy(() => import('../features/invalidated/TechList')),
    layout: LayoutDefault,
  },
  {
    auth: 'invalidateddocument',
    path: '/invalidated/certificate',
    component: lazy(() => import('../features/invalidated/CertificateList')),
    layout: LayoutDefault,
  },
  {
    auth: 'invalidateddocument',
    path: '/invalidated/detail/:id',
    component: lazy(() => import('../features/invalidated/InvalidatedDetail')),
    layout: LayoutDefault,
  },
  {
    auth: 'emailtemplatesetting',
    path: '/emailtemplate',
    component: lazy(() => import('../features/emailtemplate/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'helpmanagement',
    path: '/helpmanagement',
    component: lazy(() => import('../features/help/Management')),
    layout: LayoutDefault,
  },
  // 公共页面
  {
    path: '/announcement',
    component: lazy(() =>
      import('../features/document/report/announcement/Index')
    ),
    layout: LayoutDefault,
  },
  {
    path: '/help',
    component: lazy(() => import('../features/help/HelpPage')),
    layout: LayoutDefault,
  },
  {
    path: '/signin',
    component: lazy(() => import('../features/auth/SignIn')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/singlesignin',
    component: lazy(() => import('../features/auth/SingleSignIn')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/signin-internal-use-only',
    component: lazy(() => import('../features/auth/SignInTest')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/attachment/download/:id',
    component: lazy(() => import('../features/attachment/download')),
    layout: false,
  },
];

function standardize(items) {
  for (let item of items) {
    // menu
    if (item.auth && !item.menu) item.menu = item.auth;
    // anonymous
    item.anonymous = item.anonymous === true ? true : false;
    // exact
    if (item.exact === undefined)
      item.exact = !(item.routes && item.routes.length > 0);
    if (item.routes) standardize(item.routes);
  }
}

standardize(routes);

export default routes;
