import React from 'react';
import { IntlProvider } from 'react-intl';
// antd & dayjs
import { ConfigProvider } from 'antd';
import antdENUS from 'antd/lib/locale/en_US';
import antdZHCN from 'antd/lib/locale/zh_CN';
import 'dayjs/locale/zh-cn';
// app
import { getLocale } from './locales';
import enUS from './locales/en-US';
import zhCN from './locales/zh-CN';
import AppRouter from './app/router';
import './App.less';

const locale = getLocale();
const isZHCN = locale === 'zh-CN';
const messages = isZHCN ? zhCN : enUS;
const antdLocale = isZHCN ? antdZHCN : antdENUS;

function App() {
  return (
    <IntlProvider locale={locale} messages={messages}>
      <ConfigProvider
        componentSize="middle"
        locale={antdLocale}
        theme={{
          token: { borderRadius: 0 },
        }}
      >
        <AppRouter />
      </ConfigProvider>
    </IntlProvider>
  );
}

export default App;
