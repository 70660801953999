const messages = {
  'm.upload.tips':
    '支持JPG、PNG、BMP等图片格式，MP4、MPEG等视频格式，及PDF、Word文档等文件，单个文件不超过50M，最多上传10个附件',
  'm.delete.succress': '删除成功',
  'm.delete.documents': '是否删除选中文档？',

  'm.document.created.tip': '提交或保存后不可修改文档编号相关信息！',
  'm.document.created.snrequired': '文档编号相关字段未完善',
  'm.document.created.snexists': '文档编号已存在',
  'm.document.created.tags': '文档标签相关字段未完善',
  'm.succress': '成功',
  'm.succress.submit': '提交成功，关闭当前页面',
  'm.succress.save': '保存成功，关闭当前页面',
  'm.succress.subscription': '订阅成功！',
  'm.succress.unsubscription': '取消订阅成功！',
  'm.document.created.document': '未上传文档',
};
export default messages;
